import * as React from 'react'
import { useEffect, useState } from 'react'

import { Card, Col, Container, Dropdown, DropdownButton, FormControl, Row, Tab, Tabs } from 'react-bootstrap'

// COMPONENTS
import Layout from 'layouts/main'

import '../styles/components.css'
import Api from '../helpers/api'
import * as queryString from 'querystring'
import { config } from '../config'
import ProfilePic from '../images/profile_placeholder.png'
import axios from 'axios'
import RenderHtml from '../components/shared/renderHtml'
import SecuredRoute from '../hoc/SecuredRoute'

const StudentPage = () => {
    const [student, setStudent] = useState({})
    const [studentObjectives, setStudentObjectives] = useState([])

    const [selectedWeek, setSelectedWeek] = useState(0)
    // const [selectedMonth, setSelectedMonth] = useState(0)
    const [efficiency, setEfficiency] = useState(0)

    const [comments, setComments] = useState([])
    const [studentImages, setStudentImages] = useState({})
    const [accessDenied, setAccessDenied] = useState(false)

    useEffect(async () => {
        try {
            const token = window.localStorage.getItem('token')
            let user = (
                await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)
            ).data
            if (user.role === 'ROLE_TRUSTEE_LIMITED') {
                setAccessDenied(true)
            }
        } catch (e) {
            const { id } = queryString.parse(window.location.search.substr(1))
            window.localStorage.removeItem('user')
            window.localStorage.removeItem('token')
            window.location.href = `/?showLogin=true&next=/student?id=${id}`
        }
    }, [])

    useEffect(() => {
        const { id } = queryString.parse(window.location.search.substr(1))

        Api.getInstance()
            .getStudent(id)
            .then(response => {
                setStudent(response)
            })
            .catch(e => {
            })
            .finally(() => {
            })
        Api.getInstance()
            .getStudentObjectives(id)
            .then(response => {
                let done = 0
                let total = 0
                response.map(objective => {
                    objective.keyResults.map(keyResult => {
                        total++
                        if (keyResult.done) {
                            done++
                        }
                    })
                })
                let eff = (done / total) * 100
                setEfficiency(eff.toFixed(0))

                setStudentObjectives(response)
            })
            .catch(e => {
            })
            .finally(() => {
            })

        Api.getInstance()
            .getStudentImages()
            .then(response => {
                setStudentImages(response)
            })
            .catch(e => {
            })
            .finally(() => {
            })
    }, [])

    let w = selectedWeek * 2
    const selectedObjective = studentObjectives.find(
        objective => objective.order === w + 1,
    )
    const selectedObjective2 = studentObjectives.find(
        objective => objective.order === w + 2,
    )

    useEffect(() => {
        const { email } = student
        if (!email) {
            return
        }
        Api.getInstance()
            .getStudentComments(email)
            .then(response => {
                setComments(response)
            })
            .catch(e => {
            })
    }, [student])

    let MONTHS = {
        '1st Month': ['Week 1 - Week 2', 'Week 3 - Week 4'],
        '2nd Month': ['Week 5 - Week 6', 'Week 7 - Week 8'],
        '3rd Month': ['Week 9 - Week 10', 'Week 11 - Week 12'],
        '4th Month': ['Week 13 - Week 14', 'Week 15 - Week 16'],
        // August: ['Week 15', 'Week 16'],
    }
    let WEEKS_TILL = [0, 2, 4, 6, 8, 10, 12, 14, 16]

    const [selectedMonth, setSelectedMonth] = useState(0)

    return (
        <SecuredRoute>
            <Layout title='Students'>
                <Container className='pb-5'>
                    <link
                        href='https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css'
                        rel='stylesheet'
                        integrity='sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3'
                        crossOrigin='anonymous'
                    ></link>
                    {accessDenied && (
                        <div>You don't have permission to access this page.</div>
                    )}
                    {!accessDenied && (
                        <>
                            <Row className='mt-5 mb-5 align-items-center'>
                                <Col md='auto'>
                                    <img
                                        src={
                                            studentImages[student.email]
                                                ? `${config.CDN_BASE_URL}/${studentImages[student.email]}`
                                                : ProfilePic
                                        }
                                        style={{ width: 100, height: 100 }}
                                        className='ms-1 me-3 rounded-circle float-start float-md-none'
                                        alt=''
                                    />
                                </Col>
                                <Col>
                                    <h3 className='mt-0 fw-medium'>{student.fullName}</h3>
                                    <p className='fs-5'>
                                        <a
                                            href={`/team?id=${student.teamId}`}
                                            className='text-dark me-2'
                                        >
                                            {student.teamName}
                                        </a>
                                        |
                                        <a
                                            href={`/project?id=${student.teamId}`}
                                            className='text-dark ms-2'
                                        >
                                            {student.projectName}
                                        </a>
                                    </p>
                                </Col>
                                <Col md='auto'>
                                    <Card className='shadow-xl border-0 rounded-xl text-center py-3 px-4'>
                                        <h2 className='fw-bold'>{efficiency}/100</h2>
                                        <p className='small mb-0'>TASK EFFICIENCY</p>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className='align-items-center d-xl-none'>
                                <Col
                                    xs='12'
                                    className='d-flex justify-content-start position-relative'
                                >
                                    <DropdownButton
                                        className='calendar-select outline-none w-100'
                                        variant='outline-secondary'
                                        title='Calendar'
                                        id='input-group-dropdown-1'
                                    >
                                        <div className='px-3'>
                                            <FormControl
                                                className='outline-none border-bottom border-top-0 border-start-0 border-end-0 rounded-0'
                                                placeholder='Type a month'
                                            />
                                        </div>

                                        {Object.keys(MONTHS).map((month, index) => {
                                            return (
                                                <Dropdown.Item
                                                    onClick={e => {
                                                        setSelectedMonth(index)
                                                        setSelectedWeek(WEEKS_TILL[index])
                                                    }}
                                                    key={month}
                                                    href='#'
                                                    className='py-2'
                                                >
                                                    {month}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </DropdownButton>
                                    <div className='text-center fw-medium fs-20px position-absolute right-15px'>
                                        {Object.keys(MONTHS)[selectedMonth]}
                                    </div>
                                </Col>
                                <Col xs='12' className='d-flex'>
                                    <Tabs
                                        transition={false}
                                        className='calendar-tabs border-bottom-0 mb-3'
                                        activeKey={`${selectedWeek}`}
                                        onSelect={selectedValue => {
                                            setSelectedWeek(parseInt(selectedValue))
                                        }}
                                    >
                                        {MONTHS[Object.keys(MONTHS)[selectedMonth]].map((item, i) => {
                                            return (
                                                <Tab
                                                    eventKey={`${WEEKS_TILL[selectedMonth] + i}`}
                                                    key={`${WEEKS_TILL[selectedMonth] + i}`}
                                                    title={item}
                                                ></Tab>
                                            )
                                        })}
                                    </Tabs>
                                </Col>
                            </Row>

                            <Row className='mb-3 d-none d-xl-flex'>
                                {Object.entries(MONTHS).map(([key, value], monthIndex) => (
                                    <Col xl='auto' className='mx-auto' key={monthIndex}>
                                        <div className='text-center fw-medium fs-16px'>
                                            {key.toUpperCase()}
                                        </div>
                                        <Tabs
                                            onSelect={selectedValue => {
                                                setSelectedMonth(monthIndex)
                                                setSelectedWeek(parseInt(selectedValue))
                                            }}
                                            activeKey={`${selectedWeek}`}
                                            className='calendar-tabs border-bottom-0 mb-3'
                                        >
                                            {value.map((item, i) => (
                                                <Tab
                                                    eventKey={`${WEEKS_TILL[monthIndex] + i}`}
                                                    key={`${WEEKS_TILL[monthIndex] + i}`}
                                                    title={item}
                                                ></Tab>
                                            ))}
                                        </Tabs>
                                    </Col>
                                ))}
                                {/* {Object.entries(MONTHS).map(([key, value], monthIndex) => (
            <Col xl="2">

            </Col>
          ))} */}
                            </Row>
                            <div className='row mt-5'>
                                {selectedObjective && (
                                    <div className='col-lg-6 mb-4'>
                                        <div className='border-top border-dark pt-3'>
                                            <h3>
                                                {Object.keys(MONTHS)[selectedMonth]}:{' '}
                                                <strong>Week {w + 1} contribution</strong>
                                            </h3>
                                            <RenderHtml html={selectedObjective.keyResultDescription} />
                                        </div>
                                    </div>
                                )}
                                {selectedObjective2 && (
                                    <div className='col-lg-6 mb-4'>
                                        <div className='border-top border-dark pt-3'>
                                            <h3>
                                                {Object.keys(MONTHS)[selectedMonth]}:{' '}
                                                <strong>Week {w + 2} contribution</strong>
                                            </h3>
                                            <RenderHtml html={selectedObjective2.keyResultDescription} />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='notes'>
                                <h5>Notes from OP:</h5>

                                {comments
                                    .filter(({ week }) => Number(w) === Number(week - 1))
                                    .map(({ id, comment }) => {
                                        return <p key={'id'}>{comment}</p>
                                    })}
                            </div>
                        </>
                    )}
                </Container>
            </Layout>
        </SecuredRoute>
    )
}

export default StudentPage
